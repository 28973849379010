import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import GlobalSettings from '../GlobalSettings';


const FooterMain = () => {
    const MainContainer = styled.div`
    position: sticky;
    background: ${GlobalSettings.surrogateDarkColor};;
    padding-bottom: ${GlobalSettings.paddingAroundHTMLElements};
    padding-top: ${GlobalSettings.paddingAroundHTMLElements};

    padding-right: 64px;
    padding-left: 64px;

    @media screen and (max-width: 768px) {
      padding-right: 34px;
      padding-left: 34px;
    }

    @media screen and (max-width: 480px) {
      padding-right: ${GlobalSettings.paddingAroundHTMLElements};
      padding-left: ${GlobalSettings.paddingAroundHTMLElements};
    }
`;

  const ContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* max-width: 100%; */
  `;

  const Column = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    /* padding: 20px; */
  `;

  const Row = styled.div`
    display: grid;
    align-items: top;
    grid-gap: 64px;


    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media screen and (max-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr; 
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr; 
      grid-gap: 32px;
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      grid-gap: ${GlobalSettings.spaceAroundHTMLElements};
    }
  `;


  const Title = styled.p`
    color: #999999;
  `;

  function FooterMainContainer({children, ...restProps}) {
    return <MainContainer {...restProps}>{children}</MainContainer>
  }

  FooterMainContainer.ContainerWrapper = function FooterMainContainerContainerWrapper({children, ...restProps}) {
    return <ContainerWrapper {...restProps}>{children}</ContainerWrapper>
  }

  FooterMainContainer.Row = function FooterMainContainerRow({children, ...restProps}) {
    return <Row {...restProps}>{children}</Row>
  }

  FooterMainContainer.Column = function FooterMainContainerColumn({children, ...restProps}) {
    return <Column {...restProps}>{children}</Column>
  }


  FooterMainContainer.Title = function FooterMainContainerTitle({children, ...restProps}) {
    return <Title {...restProps}>{children}</Title>
  }

  const {t} = useTranslation('header_footer');

  const date = new Date();
  let year = date.getFullYear();

  return (
      <FooterMainContainer>
        <FooterMainContainer.ContainerWrapper>
              <FooterMainContainer.Row>

                <FooterMainContainer.Column>
                      <FooterMainContainer.Title>{t('sigle')}</FooterMainContainer.Title>
                </FooterMainContainer.Column>  

                <FooterMainContainer.Column>
                     <FooterMainContainer.Title>No RCCM: RC/DLN/2021/B/392</FooterMainContainer.Title>
                </FooterMainContainer.Column>

                <FooterMainContainer.Column>
                      <FooterMainContainer.Title>Copyright &copy; RDCT 2020-{year} </FooterMainContainer.Title>
                </FooterMainContainer.Column>

              </FooterMainContainer.Row>
        </FooterMainContainer.ContainerWrapper>
      </FooterMainContainer>
  )
}

const FooterEnd = () => {
  return (
    <FooterMain/>
  );
};

export default FooterEnd;